<template>
  <div class="page">
    <var-app-bar title="绑定邮箱" title-position="center" color="#ffffff" text-color="#000" :safe-area-top="true" >
      <template #left>
        <var-button
          color="transparent"
          text-color="#000"
          round
          text
        >
          <var-icon name="chevron-left" :size="24" />
        </var-button>
      </template>
    </var-app-bar>
    <div class="w-full">
      <div class="title">绑定邮箱</div>
      <div class="tips">请输入您要绑定的邮箱</div>
      <div class="row">
        <input class="input" type="text" placeholder="请输入邮箱" v-model="pwd">
      </div>
      <div class="row">
        <input class="input" type="text" placeholder="请输入验证码" v-model="pwd2">
        <span class="code" @click="getCode">{{ time == 60 ? '获取验证码' : `${time}秒后重新获取`}}</span>
      </div>
      <div class="btn">确认</div>
    </div>
  </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  const pwd = ref('')
  const pwd2 = ref('')
  const time = ref(60)
  // 点击获取验证码,60s倒计时
  const getCode = () => {
    time.value = 60
    const timer = setInterval(() => {
      time.value--
      if (time.value <= 0) {
        clearInterval(timer)
        time.value = 60
      }
    }, 1000)
  }

  </script>
  
  <style lang="less" scoped>
  .page {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    box-sizing: border-box;
    .w-full {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      .title {
        margin-top: 30px;
        color: #333;
        font-family: "Noto Sans SC";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .tips {
        color: #333;
        font-family: "Noto Sans SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-top: 10px;
      }
      .row {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        height: 40px;
        .input {
          flex: 1;
          outline: none;
          border: none;
          height: 40px;
        }
        .icon {
          width: 18px;
          height: 18px;
        }
        .code {
          color: #2F88FF;
          text-align: right;
          font-family: "Noto Sans SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .btn {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    background-color: #2F88FF;
    border-radius: 8px;
    text-align: center;
    line-height: 50px;
    color: #fff;
  }
  </style>
  